import React from 'react';
import { Box } from '@chakra-ui/react';
import Helmet from 'react-helmet';
import Section from './Section';
import Containers from './Containers';
import Header from './Header';
import AdminBar from './AdminBar';

type Props = {
  /** Page contents */
  children?: {};
  /** Seo Description */
  description?: string;

  /** A header image if required */
  headerImage?: {} | boolean;
  /** A section on the left hand side which can take some basic html */
  leftUtilitySection?: string | {};
  /** An seo title different to page title for seo reasons */
  seoTitle?: string;
  /** The Pages Subtitle */
  subtitle?: string | HTMLElement | {};
  /** The primary h1 title for the page  */
  title?: string;
  locale?: string;
  textAlign?: string;
};
/** A page wrapper for simple pages. Adds a basic header and some sections */
const ManualPageWrapper = ({
  children,
  description,
  title,
  headerImage,
  subtitle,
  leftUtilitySection,
  seoTitle,
  locale,
  textAlign,
}: Props) => (
  <>
    <Helmet>
      <title>{seoTitle}</title>
      <meta name="description" content={description} />
    </Helmet>
    <AdminBar />
    <Header
      title={title}
      image={headerImage}
      subtitle={subtitle}
      leftUtilitySection={leftUtilitySection}
      locale={locale}
      textAlign={textAlign}
    />
    <Section pt={[0]}>
      <Containers maxWidth={['maxWidth']} mx="auto" mb={[16]}>
        <Box width="100%">{children}</Box>
      </Containers>
    </Section>
  </>
);

export default ManualPageWrapper;
