import React from 'react';
import { Button } from '@chakra-ui/react';
import ManualPageWrapper from '../components/ui/ManualPageWrapper';
import { useLocalStorage } from '../hooks/useLocalStorage';

const Boss = () => {
  const [showAdminBar, setShowAdminBar] = useLocalStorage(
    'showAdminBar',
    false
  );
  return (
    <ManualPageWrapper
      title="Admin"
      subtitle={
        <p>
          <Button
            className="primary"
            variant="primary"
            onClick={() => setShowAdminBar(true)}
          >
            Show admin bar
          </Button>
        </p>
      }
    />
  );
};

export default Boss;
